import React, { useState, useCallback, useEffect } from "react";
import { ConversationAi } from "@visup/conversation-ai";
import { Dimensions } from "react-native";

const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

export default function App() {
  const [wsUrl, setWsUrl] = useState<string | undefined>(undefined);
  const [apiBaseUrl, setApiBaseUrl] = useState<string>("");
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [scrollToMessageTimestamp, setScrollToMessageTimestamp] =
    useState<string>();
  const [primaryColor, setPrimaryColor] = useState<string | undefined>(
    undefined
  );
  const [messageMaxWidth, setMessageMaxWidth] = useState<number>();
  const [windowDimensions, setWindowDimensions] = useState({
    height: windowHeight,
    width: windowWidth,
  });
  const [messagesContainerStyle, setMessagesContainerStyle] = useState<{
    height: number;
  }>();
  const [messagesAndFormContainerStyle, setMessagesAndFormContainerStyle] =
    useState<{
      height: number;
      maxWidth: number;
      borderRadius: number;
    }>();
  const [showDebug, setShowDebug] = useState(false);
  const [debugStyle, setDebugStyle] = useState<{ height: number }>();
  const [
    messagesAndFormContainerBorderRadius,
    setMessagesAndFormContainerBorderRadius,
  ] = useState<number>(16);
  const [bodyPadding, setBodyPadding] = useState<number>(16);
  const [botIconSize, setBotIconSize] = useState<number>(36);
  const [botIcon, setBotIcon] = useState<string>();
  const [welcomeMessages, setWelcomeMessages] = useState<{ label: string; language: string; }[] | undefined>();
  const [chatMaxWidth, setChatMaxWidth] = useState<number>(800);
  const [organizationId, setOrganizationId] = useState<string>("");
  const [centerChat, setCenterChat] = useState<boolean>(false);
  const [host, setHost] = useState<string | undefined>();
  const [requesterId, setRequesterId] = useState<string | undefined>();

  const handleParams = (params: Record<string, string>) => {
    if (params.apiBaseUrl && params.apiBaseUrl !== "") {
      setApiBaseUrl(params.apiBaseUrl);
    }
    if (params.wsUrl && params.wsUrl !== "") {
      setWsUrl(params.wsUrl);
    }
    if (params.authToken && params.authToken !== "") {
      setAuthToken(params.authToken);
    }
    if (
      params.scrollToMessageTimestamp &&
      params.scrollToMessageTimestamp !== ""
    ) {
      setScrollToMessageTimestamp(params.scrollToMessageTimestamp);
    }
    if (params.primaryColor && params.primaryColor !== "") {
      setPrimaryColor(params.primaryColor);
    }

    if (params.showDebug && params.showDebug !== "") {
      setShowDebug(!!params.showDebug);
    }
    if (params.anonymous && params.anonymous !== "") {
      setAnonymous(!!params.anonymous);
    }
    if (params.padding && params.padding !== "") {
      setBodyPadding(parseInt(params.padding, 10));
    }
    if (params.borderRadius && params.borderRadius !== "") {
      setMessagesAndFormContainerBorderRadius(
        parseInt(params.borderRadius, 10)
      );
    }
    if (params.botIcon && params.botIcon !== "") {
      setBotIcon(params.botIcon);
    }

    if (params.welcomeMessage_it && params.welcomeMessage_it !== "") {
      setWelcomeMessages(wm => {
        if (wm === undefined) {
          return [{ label: params.welcomeMessage_it, language: 'it' }];
        }
        return [...wm, { label: params.welcomeMessage_it, language: 'it' }]
      });
    }

    if (params.welcomeMessage_en && params.welcomeMessage_en !== "") {
      setWelcomeMessages(wm => {
        if (wm === undefined) {
          return [{ label: params.welcomeMessage_en, language: 'en' }];
        }
        return [...wm, { label: params.welcomeMessage_en, language: 'en' }]
      });
    }

    if (params.welcomeMessage_fr && params.welcomeMessage_fr !== "") {
      setWelcomeMessages(wm => {
        if (wm === undefined) {
          return [{ label: params.welcomeMessage_fr, language: 'fr' }];
        }
        return [...wm, { label: params.welcomeMessage_fr, language: 'fr' }]
      });
    }

    if (params.welcomeMessage_de && params.welcomeMessage_de !== "") {
      setWelcomeMessages(wm => {
        if (wm === undefined) {
          return [{ label: params.welcomeMessage_de, language: 'de' }];
        }
        return [...wm, { label: params.welcomeMessage_de, language: 'de' }]
      });
    }

    if (params.welcomeMessage_es && params.welcomeMessage_es !== "") {
      setWelcomeMessages(wm => {
        if (wm === undefined) {
          return [{ label: params.welcomeMessage_es, language: 'es' }];
        }
        return [...wm, { label: params.welcomeMessage_es, language: 'es' }]
      });
    }

    if (params.chatMaxWidth && params.chatMaxWidth !== "") {
      setChatMaxWidth(parseInt(params.chatMaxWidth, 10));
    }
    if (params.organizationId && params.organizationId !== "") {
      setOrganizationId(params.organizationId);
    }
    if (params.centerChat && params.centerChat !== "") {
      setCenterChat(!!params.centerChat);
    }
    if (params.host && params.host !== "") {
      setHost(params.host);
    }
    if (params.requesterId && params.requesterId !== "") {
      setRequesterId(params.requesterId);
    }
  };

  const getQueryParams = (): Record<string, string> => {
    const params = new URLSearchParams(window.location.search);
    let queryParams: Record<string, string> = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }
    return queryParams;
  };

  useEffect(() => {
    // Parse query parameters
    const queryParams = getQueryParams();
    handleParams(queryParams);
    // Add event listener for postMessage
    const handlePostMessage = (event: MessageEvent) => {
      if (event.data.type === "updateAuthToken") {
        setAuthToken(event.data.token);
      }
    };

    window.addEventListener("message", handlePostMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.padding = `${bodyPadding}px`;
      body.style.height = `calc(100vh - ${bodyPadding * 2}px)`;
    }
    let maxWidth = Math.min(windowDimensions.width / 2, 500);
    if (windowDimensions.width < 500) {
      maxWidth = windowDimensions.width - 64;
      setBotIconSize(22);
    } else {
      setBotIconSize(36);
    }
    setMessageMaxWidth(maxWidth);
    setMessagesContainerStyle({
      height: wsUrl
        ? windowDimensions.height - 84 - bodyPadding * 2
        : windowDimensions.height - bodyPadding * 2,
    });
    setMessagesAndFormContainerStyle({
      height: windowDimensions.height - bodyPadding * 2,
      maxWidth: chatMaxWidth,
      borderRadius: messagesAndFormContainerBorderRadius,
    });
    setDebugStyle({
      height: windowHeight - bodyPadding * 2,
    });
    const dimensionsSubscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setWindowDimensions({ height: window.height, width: window.width });
      }
    );

    return () => {
      dimensionsSubscription.remove();
    };
  }, [
    windowDimensions.height,
    windowDimensions.width,
    messagesAndFormContainerBorderRadius,
    bodyPadding,
    wsUrl
  ]);

  const styleCustomization = {
    messageMaxWidth,
    messagesContainerStyle,
    messagesAndFormContainerStyle,
    debugStyle,
    botIconSize: botIconSize,
    botIcon: botIcon,
    centerChat,
  } as any;
  if (primaryColor) {
    styleCustomization.primaryColor = primaryColor;
  }

  return (
    <>
      {(authToken || anonymous) && (
        <ConversationAi
          apiBaseUrl={apiBaseUrl}
          authToken={authToken}
          scrollToMessageTimestamp={scrollToMessageTimestamp}
          wsUrl={wsUrl}
          styleCustomization={styleCustomization}
          showDebug={showDebug}
          anonymous={anonymous}
          welcomeMessages={welcomeMessages}
          organizationId={organizationId}
          host={host}
          requesterId={requesterId}
        ></ConversationAi>
      )}
    </>
  );
}
